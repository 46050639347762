import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import * as typeformEmbed from "@typeform/embed"
import Cookies from "js-cookie"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import { Layout, CommunityLibCard, MetaData, EventCard } from "../components"
import { BoxedContainer, Section } from "../elements"
import theme from "../styles/theme"

// Import styles
import "../styles/community.scss"

// Import images
import hero__ambassador from "../images/community/traefik-ambassador-stickers-1.svg"
import hero__downloads from "../images/community/hero/downloads-icon.svg"
import hero__github from "../images/community/hero/github-icon.svg"
import hero__contributors from "../images/community/hero/contributors-icon.svg"
import intro__traefik from "../images/logos/traefik-proxy-logo.svg"
import intro__download from "../images/community/download.svg"
import intro__read from "../images/community/read.svg"
import intro__bug from "../images/community/report-bug.svg"
import intro__contribute from "../images/community/contribute.svg"
import intro__quickstart from "../images/community/quickstart.svg"
import intro__maesh from "../images/logos/traefik-mesh-logo.svg"
import ambassador_cta_sticker from "../images/community/traefik-ambassador-stickers-2.svg"
import links__community from "../images/community/community-icon.svg"
import links__github from "../images/community/github-icon.svg"
import links__twitter from "../images/community/twitter-icon.svg"
import links__youtube from "../images/community/youtube-icon.svg"
import links__linkedin from "../images/community/linked-in-icon.svg"

interface Props {
  data: any
  location: any
}

const CommunityPage = ({ data, location }: Props) => {
  const communityLibPosts = data.communityLibPosts.edges
  const events = data.events.edges
  let community_survey: any

  let events_section
  if (events.length == 0) {
    events_section = <p className="empty-feed-message">No upcoming events</p>
  } else {
    events_section = events.map(({ node }) => (
      <EventCard key={node.id} post={node} />
    ))
  }

  useEffect(() => {
    community_survey = typeformEmbed.makePopup(
      "https://traefikambassador.typeform.com/to/RE8zVY",
      {
        hideHeaders: true,
        hideFooter: true,
      }
    )
  }, [])

  // Hubspot form
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault()

    const isBrowser = typeof window !== "undefined"
    const hutk = isBrowser ? Cookies.get("hubspotutk") : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null
    const postUrl =
      "https://api.hsforms.com/submissions/v3/integration/submit/5814022/195f7321-e7f5-4fe0-9c3e-49466380956f"

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "newsletter",
          value: "Yes",
        },
        {
          name: "lifecyclestage",
          value: "subscriber",
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    }

    fetch(postUrl, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
      }),
    })
      .then(res => res.json())
      .then(() => {
        setMessage("Thanks for submitting the form!")
        setEmail("")
      })
      .catch(err => {
        setMessage("Oops, something has gone wrong")
      })
  }

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />

      <div className="community-page">
        {/* Hero */}
        <section className="community-hero page-hero">
          <div className="wrapper-1200">
            <div className="community-hero__icon">
              <img src={hero__ambassador} alt="Traefik Community" />
            </div>
            <h1>Join the Traefik Community!</h1>
            <h2 className="page-hero__subtitle">
              Get started, get support, and learn from others in the Traefik
              Community.
            </h2>
            <div className="community-hero__cta">
              <a
                href="https://community.traefik.io/"
                className="button--primary button--extra-padding button--with-shadow"
              >
                Join the Forum
              </a>
            </div>
            <div className="community-stats">
              <span className="title-uppercase">
                Global, Diverse, 100% Community Driven
              </span>
              <div className="statistics">
                <div className="statistic">
                  <div className="icon">
                    <img src={hero__downloads} alt="downloads" />
                  </div>
                  <div className="content">
                    <div className="title">2 Billion+</div>
                    <div className="sub-title">Downloads</div>
                  </div>
                </div>
                <div className="statistic statistic--github">
                  <div className="icon">
                    <img src={hero__github} alt="github" />
                  </div>
                  <div className="content">
                    <div className="title">30K+</div>
                    <div className="sub-title">Stars on Github</div>
                  </div>
                </div>
                <div className="statistic">
                  <div className="icon">
                    <img src={hero__contributors} alt="contributors" />
                  </div>
                  <div className="content">
                    <div className="title">500+</div>
                    <div className="sub-title">Contributors</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Introduction */}
        <section className="community-intro page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Let’s Get Started</h2>
              <p className="sub-title">
                Your journey begins here. The friendly and active Traefik
                community is looking forward to welcoming your contributions and
                collaborating with you!
              </p>
            </div>

            <div className="community-intro__cards">
              <div className="community-intro__card community-intro__card--traefik">
                <div className="community-intro__card-image"></div>

                <div className="community-intro__card-content">
                  <h3>
                    <img
                      className="logo"
                      src={intro__traefik}
                      alt="Traefik, the Cloud Native Edge Router"
                    />
                  </h3>
                  <p>The Cloud Native Edge Router</p>
                  <div className="links">
                    <a href="https://github.com/traefik/traefik/releases">
                      <span className="icon">
                        <img src={intro__download} />
                      </span>
                      <span className="text">Download</span>
                    </a>
                    <a href="https://docs.traefik.io/">
                      <span className="icon">
                        <img src={intro__read} />
                      </span>
                      <span className="text">Read Docs</span>
                    </a>
                    <a href="https://docs.traefik.io/contributing/submitting-issues/">
                      <span className="icon">
                        <img src={intro__bug} />
                      </span>
                      <span className="text">Report Bug</span>
                    </a>
                    <a href="https://docs.traefik.io/contributing/thank-you/">
                      <span className="icon">
                        <img src={intro__contribute} />
                      </span>
                      <span className="text">Contribute</span>
                    </a>
                    <a href="https://docs.traefik.io/getting-started/install-traefik/">
                      <span className="icon">
                        <img src={intro__quickstart} />
                      </span>
                      <span className="text">Quickstart</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="community-intro__card community-intro__card--maesh">
                <div className="community-intro__card-image"></div>

                <div className="community-intro__card-content">
                  <h3>
                    <img
                      className="logo"
                      src={intro__maesh}
                      alt="Maesh, the Simpler Service Mesh"
                    />
                  </h3>
                  <p>Simpler Service Mesh</p>
                  <div className="links">
                    <a href="https://github.com/traefik/mesh/releases">
                      <span className="icon">
                        <img src={intro__download} />
                      </span>
                      <span className="text">Download</span>
                    </a>
                    <a href="https://docs.mae.sh/">
                      <span className="icon">
                        <img src={intro__read} />
                      </span>
                      <span className="text">Read Docs</span>
                    </a>
                    <a href="https://docs.mae.sh/contributing/submitting-issues/">
                      <span className="icon">
                        <img src={intro__bug} />
                      </span>
                      <span className="text">Report Bug</span>
                    </a>
                    <a href="https://docs.mae.sh/contributing/thank-you/">
                      <span className="icon">
                        <img src={intro__contribute} />
                      </span>
                      <span className="text">Contribute</span>
                    </a>
                    <a href="https://docs.mae.sh/quickstart/">
                      <span className="icon">
                        <img src={intro__quickstart} />
                      </span>
                      <span className="text">Quickstart</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Ambassador CTA */}
        <section className="community-ambassador-cta">
          <div className="wrapper-1200">
            <div className="image">
              <img
                src={ambassador_cta_sticker}
                alt="Become a Traefik Ambassador"
              />
            </div>
            <div className="content">
              <h2>Become a Traefik Ambassador</h2>
              <p>
                The Traefik Ambassador program is built to support and reward
                contributors of code, content, and community building.
                <br />
                Join today to become part of the global team of Traefik
                Ambassadors.
              </p>
              <Link
                to="/traefik-ambassador-program/"
                className="button--primary button--with-shadow button--extra-padding"
              >
                BECOME AN AMBASSADOR
              </Link>
            </div>
          </div>
        </section>

        {communityLibPosts.length > 0 && (
          <Section.Container
            withPadding
            backgroundColor={theme.colors.background.light}
          >
            <BoxedContainer>
              <Section.HeaderCenterAligned
                title="Community Library"
                accentColor={theme.colors.accent.orange.base}
              />
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 700: 1, 900: 2, 1024: 3 }}
              >
                <Masonry>
                  {communityLibPosts.map(post => (
                    <CommunityLibCard key={post.node.id} data={post} />
                  ))}
                </Masonry>
              </ResponsiveMasonry>
              <Section.CTAs>
                <Link
                  className="button--secondary button--secondary--with-arrow"
                  to="/community-library/"
                >
                  Explore More
                </Link>
              </Section.CTAs>
            </BoxedContainer>
          </Section.Container>
        )}

        {/* Links */}
        <section className="community-links">
          <div className="wrapper-1200">
            <div className="community-links__content">
              <h2>Join the conversation</h2>
              <p className="sub-title">
                Connect with the community, ask questions, get answers, find
                resources, and share knowledge.
              </p>
              <div className="community-subscribe">
                <small>
                  Receive the latest news from the community, exclusive
                  invitations to our events and online meetups, and more.
                </small>
                <form
                  className="community-subscribe__form"
                  onSubmit={submitForm}
                >
                  <div className="email-field">
                    <input
                      className="input--email"
                      type="email"
                      name="email"
                      placeholder="Your email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                    <div className="email-symbol">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="13"
                        viewBox="0 0 20 13"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path d="M-2-5h24v24H-2z" />
                          <path
                            fill="#B8BDC1"
                            d="M19.997 1.973L20 2v9c0 1.102-.897 2-2 2H2c-1.103 0-2-.898-2-2V2c0-.008.003-.017.003-.026l9.47 5.877a1 1 0 0 0 1.054-.001zM18 .001c.352 0 .677.1.965.26L10 5.824 1.035.261c.287-.16.613-.26.965-.26z"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <button className="button--primary" type="submit">
                    Get Started
                  </button>
                </form>
                <p
                  style={{
                    fontSize: "14px",
                    margin: "20px 0 0",
                    display: message.length === 0 ? "none" : "block",
                  }}
                >
                  {message}
                </p>
              </div>
            </div>

            <div className="community-links__icons">
              <a href="https://community.traefik.io" rel="nofollow">
                <img src={links__community} alt="Forum" />
                <span>Forum</span>
              </a>
              <a href="https://github.com/traefik/" rel="nofollow">
                <img src={links__github} alt="Github" />
                <span>Github</span>
              </a>
              <a href="https://twitter.com/traefik" rel="nofollow">
                <img src={links__twitter} alt="Twitter" />
                <span>Twitter</span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCVRyfsfkok4trq6u7RJsY_A"
                rel="nofollow"
              >
                <img src={links__youtube} alt="Youtube" />
                <span>Youtube</span>
              </a>
              <a
                href="https://www.linkedin.com/company/traefik/"
                rel="nofollow"
              >
                <img src={links__linkedin} alt="Linkedin" />
                <span>Linkedin</span>
              </a>
            </div>
          </div>
        </section>

        {/* Events */}
        <section className="community-events page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Upcoming Events</h2>
              <p className="sub-title">
                Attend Traefik Online Meetups and events around the world!
              </p>
            </div>

            <div className="community-events__list event-feed">
              {events_section}
            </div>

            <div className="community-events__cta">
              <div className="illustration">
                <img
                  srcSet="/images/community/events-cta/graphic.png 1x,
                        /images/community/events-cta/graphic@2x.png 2x,
                        /images/community/events-cta/graphic@3x.png 3x"
                  src="/images/community/events-cta/graphic.png"
                />
              </div>

              <div className="content">
                <span>Would you like to present at an online meetup?</span>
                <button
                  id="community-survey-link"
                  className="button--primary button--extra-padding button--with-shadow"
                  onClick={() => {
                    if (community_survey) community_survey.open()
                  }}
                >
                  Get in Touch
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default CommunityPage

export const query = graphql`
  query {
    communityLibPosts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "community-library" } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 6
    ) {
      edges {
        node {
          ...GhostPostCardFields
          childHtmlRehype {
            htmlAst
          }
        }
      }
    }

    events: allGhostPost(
      filter: { primary_tag: { slug: { eq: "events" } } }
      limit: 3
      sort: { order: ASC, fields: published_at }
    ) {
      edges {
        node {
          ...GhostPostCardFields
          html
        }
      }
    }

    current_page: ghostPage(slug: { eq: "community" }) {
      ...PageMetaFields
    }
  }
`
